import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from 'vuelidate';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import logger from './utils/logger'; // Import the logger

// Custom components and styles
import "./components/smallComponents/index";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './style.scss';
import api from "./api";
import warehouseApi from "./warehouseApi";
import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css';

Vue.config.productionTip = false;

// Plugin usage
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);

const options = {
    toastClassName: "custom-global-toast",
  };

Vue.use(Toast, options);
Vue.component('Modal', VueModal);

// Global filters
Vue.filter('dateTimeFilter', function (value) {
    if (!value) return '';
    const localeValue = new Date(value);
    return localeValue.toLocaleDateString() + ' ' + localeValue.getHours().toString().padStart(2, '0') + ':' + localeValue.getMinutes().toString().padStart(2, '0');
});

Vue.filter('dateFilter', function (value) {
    if (!value) return '';
    return new Date(value).toLocaleDateString();
});

// Mixin for showing toasts
Vue.mixin({
    methods: {
        makeBootstrapToast: function(title, message, variant = null) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                autoHideDelay: 5000,
                appendToast: false,
                noCloseButton: true,
            });
        },
        showToast: function(message, type = "default") {
            this.$toast(message, {
                type: type, // e.g., "success", "error"
            });
        }
    }
});

// Environment-specific API URL setup
let api_url = "api_url";
if (process.env.NODE_ENV === "development") {
    api_url = "api_url_dev";
}

// Fetching configuration and initializing Vue application
fetch('/config.json')
    .then(res => res.json())
    .then(config => {
        api.Url = config[api_url];
        warehouseApi.Url = config[api_url];
        new Vue({
            data: () => ({
                urlConfig: config
            }),
            router,
            store,
            render: h => h(App)
        }).$mount('#app');
    });

logger.info('Logger is set up and ready to use globally.');

Vue.config.errorHandler = function (err, vm, info) {
    logger.error(`Vue error: ${err.toString()}\nInfo: ${info}`, { err, vm });
};

window.addEventListener('unhandledrejection', function (event) {
    logger.error('Unhandled promise rejection', { event });
});

window.addEventListener('error', function (event) {
    logger.error('JavaScript error', { event });
});