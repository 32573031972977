<template>
  <div>

    <b-row align-h="center" align-v="center" class="full-height  login-container mb-0">
      <b-col cols="3">
        <b-card class="mb-2">
          <b-card-header>
            <img src="@/assets/lcslogo.png" alt="" class="img-to-fit">
          </b-card-header>
          <b-card-text>
            <b-row class="mb-0 px-0">
              <b-col cols="12" class="px-0 mb-2" v-for="(item, index) in messages" :key="index">
                <b-alert show dismissible :variant="item.type">{{ item.message }}</b-alert>
              </b-col>
              <b-col cols="12" class="px-0 mt-2">
                <b-form-group id="email-input-group" label="Email Address" label-for="email">
                  <b-form-input
                      id="email"
                      name="email"
                      v-model="reset_model.email"
                      :state="validateState('email')"
                      aria-describedby="email-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="email-live-feedback"
                  >{{ this.invalidMesg('email') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-0 px-0">
              <b-col cols="12" class="px-0 mb-2" v-for="(item, index) in messages" :key="index">
                <b-alert show dismissible :variant="item.type">{{ item.message }}</b-alert>
              </b-col>
              <b-col cols="12" class="px-0 mt-2">
                <b-form-group id="company-input-group" label="Password" label-for="password">
                  <b-form-input
                      id="password"
                      name="password"
                      v-model="reset_model.password"
                      :state="validateState('password')"
                      aria-describedby="password-live-feedback"
                      type="password"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="password-live-feedback"
                  >{{ this.invalidMesg('password') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-0 px-0">
              <b-col cols="12" class="px-0 mb-2" v-for="(item, index) in messages" :key="index">
                <b-alert show dismissible :variant="item.type">{{ item.message }}</b-alert>
              </b-col>
              <b-col cols="12" class="px-0 mt-2">
                <b-form-group id="passwordConfirm-input-group" label="Password Confirm" label-for="passwordConfirm">
                  <b-form-input
                      id="passwordConfirm"
                      name="passwordConfirm"
                      v-model="reset_model.passwordConfirm"
                      :state="validateState('passwordConfirm')"
                      aria-describedby="passwordConfirm-live-feedback"
                      type="password"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="passwordConfirm-live-feedback"
                  >{{ this.invalidMesg('passwordConfirm') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row align-h="center" class="mx-0 mb-0">
              <b-button class="mt-3 primary" @click="reset()" size="sm" variant="outline-primary" block squared>
                <b-spinner small v-if="isLoading"></b-spinner>
                Change Password
              </b-button>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

  </div>

</template>

<script>
import notificationService from '@/store/notificationService'
import {mapActions, mapState} from 'vuex'
import api from "../api";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  mixins: [validationMixin],
  data: () => ({
    state: 'login',
    showErrors: false,
    errors: [],
    reset_model: {
      email: null,
      password: null,
      token: ""
    },
    isLoading: false,

  }),
  beforeCreate() {
    if (!this.$store.hasModule('notificationService')) {
      this.$store.registerModule('notificationService', notificationService);
    }
  },

  mounted() {
    this.reset_model.token = this.$route.query.token
    if (this.reset_model.token === undefined) {
      this.makeToast("Reset Password Error", "Reset token not provided", "Danger")
    }

  },
  validations: {
    reset: {

      email: {
        required,
        email
      },
      password: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        },
        minLength: minLength(9),
        maxLength: maxLength(19)
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs("password")
      },

    }
  },
  methods: {
    ...mapActions(['resetPassword']),
    ...mapActions('notificationService', ['addErrorMessage', 'addSuccessMessage']),
    reset() {
      this.isLoading = true;

      const s = (data) => {
        console.log(data);
        this.isLoading = false;
        // window.location = "/"
        this.$router.push({path: "/"})
      }

      const e = (error) => {
        console.error(error);
        this.makeToast("Reset Password","Failed to reset password","danger")
        this.isLoading = false;
      }
      this.reset_model.token = this.reset_model.token.replace(/\s/g, "+")
      var resetModel = {
        email: this.reset_model.email,
        token: this.reset_model.token,
        password: this.reset_model.password
      }
      api.FinishResetPassword(resetModel, s, e)
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.reset[name];
      return $dirty ? !$error : null;
    },
    invalidMesg(name) {
      //Returns appropriate error message for end user.
      switch (name) {
        case "email": {
          return "This is a required field and must be a valid Email address."
        }
        case "password": {
          return "This is a required field and must be at least 9 characters, must contain an Upper case and lower case letter and at lease one special character."
        }
        case "passwordConfirm": {
          return "Passwords no not Match."
        }

      }
    },
  },
  computed: {
    ...mapState('notificationService', ['messages'])
  }
}
</script>