<template>
    <div>
        <b-row align-h="center" align-v="center" class="full-height  login-container mb-0">
            <b-col cols="3">
                <b-card class="mb-2">
                    <b-card-header>
                        <img src="@/assets/lcslogo.png" alt="" class="img-to-fit">
                    </b-card-header>
                    <b-card-text>
                        <b-row class="mb-0 px-0">
                        <b-col cols="12" class="px-0 mb-2"  v-for="(item, index) in messages" :key ="index">
                            <b-alert show dismissible :variant="item.type">{{item.message}}</b-alert>
                        </b-col>
                            <b-col cols="12" class="px-0">                               
                                    <b-form-input v-model="email" placeholder="Email"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row align-h="center" class="mx-0 mb-0">
                            <b-button class="mt-3 primary" @click="reset()" size="sm" variant="outline-primary" block squared>
                                <b-spinner small v-if="isLoading"></b-spinner>
                                Send Reset Password
                            </b-button>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>

</template>
<style >
.primary{
background-color: #ffcb14!important;
color: #222222!important;
}
.primary:hover{
    color: #fff!important;
}
</style>
<script>
import { mapActions, mapState } from 'vuex'
import notificationService from '@/store/notificationService'
import api from "../api";
export default {
    components: {
    },
    data: () => ({
        showErrors: false, 
        errors: [],
        email: null,
        password: null,
        isLoading: false
    }),
    beforeCreate() {
        if (!this.$store.hasModule('notificationService')) {
        this.$store.registerModule('notificationService', notificationService);
        }
    },
    methods:{
        ...mapActions(['sendRequestToResetPassword']),
        reset(){
            this.isLoading = true;

            const s = (data) => {
              console.log(data)
              this.isLoading = false;
              this.$router.push({path: '/'})
            };

            const e = (error) => {
              console.error(error);
              this.isLoading = false;
            };

            api.resetPassword(this.email, s, e)
        }
    },
    computed:{
        ...mapState('notificationService', ['messages'])
    }
}
</script>

