export default{
    namespaced: true,
    state:{
        messages: []
    },
    mutations:{
        addMessage: (state, payload) => {
            state.messages.push(payload)
        },
    },
    actions: {
        addErrorMessage({state}, err){
            if (err.error.search('400') !== -1) {
                state.messages.push({message: 'Request could not be processed. Please refresh and try again or contact server administrator', type: "danger"})
            } else if (err.error.search('401') !== -1) {
                state.messages.push({message: 'Unauthorized access denied. Please log in to continue', type: "danger"})
            } else  if (err.error.search('403') !== -1) {
                state.messages.push({message: 'Access denied.', type: "danger"})
            } else if (err.error.search('404') !== -1) {
                state.messages.push({message: 'The request was not found. Please try again or contact server administrator', type: "danger"})
            } else if (err.error.search('500') !== -1) {
                state.messages.push({message: 'The server could not process the request. Please contact server administrator', type: "danger"})
            } else {
                state.messages.push({message: err.message, type: "danger"})
            }
        },
        addWarningMessage({state}, err){
            state.messages.push({message: err, type: "warning"})
        },
        addSuccessMessage({state}, err){
            state.messages.push({message: err, type: "success"})
        }
    }
}