import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl: '',
    userIdentifier: null,
    user: null,

    //request objects
    loginRequest: null,
    resetPasswordRequest: null,
    userSearchRequest: null,
    userCreateRequest: null,
    userPasswordRequest: null,

  },
  mutations: {
  
    setSelectedUser: (state, payload) => {state.selectedUser = payload},
    //set requests and the likes
    setApiUrl: (state, payload) => {
      state.baseUrl = payload
      localStorage.setItem('apiUrl', payload)
    },

    setLoginRequest: (state, payload) => {state.loginRequest = payload},
    setResetPasswordRequest: (state, payload) => {state.resetPasswordRequest = payload},
  },
  actions: {
    //log in screen
 
  },
  modules: {
  }
})
