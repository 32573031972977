<template>
  <div>
    <b-row align-h="center" align-v="center" class="full-height  login-container mb-0 flex-row">
      <b-col :class="this.mobileAndTabletCheck()? '' : 'col-3'">
        <b-card class="mb-2">
          <b-card-header>
            <img src="@/assets/lcslogo.png" alt="" class="img-to-fit" width="50%">
          </b-card-header>
          <h3 style="text-align: center;">Welcome to LCS Gateway</h3>
          <p style="text-align: center;">The ultimate supply chain and logistics framework for next generation
            consumers.</p>
          <br/>
          <b-card-text>
            <b-row class="mb-0 px-0">
              <b-col cols="12" class="px-0 mb-2" v-for="(item, index) in messages" :key="index">
                <b-alert show dismissible :variant="item.type">{{ item.message }}</b-alert>
              </b-col>
              <b-col cols="12" class="px-0">

                <b-form-input type="email" v-model="email" placeholder="Email"></b-form-input>
              </b-col>
              <b-col cols="12" class="px-0 mt-2">

                <b-form-input v-model="password" type="password" placeholder="Password"></b-form-input>
              </b-col>
            </b-row>
            <b-row align-h="center" class="mx-0 mb-0">
              <b-button class="mt-3 primary" @click="loginUser()" size="sm" :variant="filled" block squared>
                <b-spinner small v-if="isLoading"></b-spinner>
                Login
              </b-button>
            </b-row>
            <b-row class="mx-0">

              <b-button class="mt-3 primary-no-border" @click="forgotPassword()" size="sm" variant="outline-primary"
                        block squared style="background-color: transparent; color: #222222">
                Forgot Password?
              </b-button>

              <b-button class="mt-3 primary-no-border" @click="register()" size="sm" variant="outline-primary" block
                        squared style="background-color: transparent; color: #222222">
                Register
              </b-button>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.primary {
  background-color: #ffcb14 !important;
  color: #222222 !important;
}

.primary:hover {
  color: #fff !important;
}
</style>
<script>
import {mapActions, mapState} from 'vuex'
import notificationService from '@/store/notificationService'
import api from "../api";


export default {
  components: {},
  data: () => ({

    showErrors: false,
    errors: [],
    email: null,
    password: null,
    isLoading: false
  }),
  mounted() {
    this.$store.commit('setApiUrl', this.$root.urlConfig.api_url)
  },
  beforeCreate() {
    if (!this.$store.hasModule('notificationService')) {
      this.$store.registerModule('notificationService', notificationService);
    }
  },
  methods: {

    ...mapActions('notificationService', ['addErrorMessage', 'addSuccessMessage']),

    mobileAndTabletCheck() {
      let check = false;
      // eslint-disable-next-line
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    forgotPassword() {
      this.$router.push({path: '/forgot'})
    },
    register() {
      this.$router.push({path: '/Register'})
    },
    async loginUser() {
      localStorage.clear()
      this.isLoading = true;
      const s = async (data) => {

        this.userData = data;

        // This needs to be adapted to take in the new jwt token and get these values from it.

        // Current data returned from server: {"token":"jwt-token","expiration":"2022-04-11T13:38:12Z"}

        var base64Url = data.token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        var currentData = JSON.parse(jsonPayload);

        await localStorage.setItem("api_Key", data.token);
        await localStorage.setItem("role", currentData.role);
        currentData.role = JSON.parse(currentData.role)
         currentData.AssociatedEntity = JSON.parse(currentData.AssociatedEntity)
        if (currentData.AssociatedEntity===null){
          currentData.AssociatedEntity={Name:"Unknown"}
        }
        await localStorage.setItem("user_data", JSON.stringify(currentData));

         // await this.$router.push({path: "/home"})

      };

      const e = (msg) => {
        console.log(msg);
        if (typeof msg !== 'string') {
          if (msg.errors.Email != null && msg.errors.Email.length > 0) {
            this.makeToast(msg.errors.Email[0]);
            this.isLoading = false;
            return;
          }

          if (msg.errors.Password != null && msg.errors.Password.length > 0) {
            this.makeToast(msg.errors.Password[0]);
            this.isLoading = false;
            return;
          }
        }

        this.makeToast(msg);
        this.isLoading = false;
      };

      api.getAuthLogin(this.email, this.password, s,()=>{window.location="/home"}, e);
    },

    makeToast(message) {
      this.$bvToast.toast(`${message}`, {
        title: 'Something went wrong.',
        autoHideDelay: 5000,
        appendToast: false
      })
    },

    hideVerificationEmailModal() {
      this.$refs['email-verification-modal'].hide()
    },
    showVerificationEmailModal() {
      this.$refs['email-verification-modal'].show()
    },
  },
  computed: {
    ...mapState('notificationService', ['messages']),
    filled() {
      if (this.email !== null && this.password !== null && this.email !== '' && this.password !== '') {
        return 'primary'
      } else {
        return 'outline-primary'
      }
    }
  }
}
</script>

