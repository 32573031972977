<template>
  <div>

    <b-row align-h="center" align-v="center" class="full-height  login-container mb-0">
      <b-col cols="3">
        <b-card class="mb-2">
          <b-card-header>
            <img src="@/assets/lcslogo.png" alt="" class="img-to-fit">
          </b-card-header>
          <b-card-text>
            <div v-if="showMsg">
              You have successfully registered – you will be redirected to the LCS Gateway application
              <br/>
              <button @click="redirect()">Click Here to Redirect</button>
            </div>
            <div v-if="!showMsg">
              <b-form @submit.stop.prevent="onSubmit">
                <b-row class="mb-0 px-0">
                  <b-col cols="12" class="px-0 mb-2" v-for="(item, index) in messages" :key="index">
                    <b-alert show dismissible :variant="item.type">{{ item.message }}</b-alert>
                  </b-col>
                  <b-col cols="12" class="px-0 mt-2">

                    <b-form-group id="name-input-group" label="Name" label-for="name">
                      <b-form-input
                          id="name"
                          name="name"
                          v-model="$v.user.name.$model"
                          :state="validateState('name')"
                          aria-describedby="name-live-feedback"
                      ></b-form-input>

                      <b-form-invalid-feedback
                          id="name-live-feedback"
                      >{{ this.invalidMesg('name') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!--                  <label>Name</label>-->
                    <!--                  <b-form-input v-model="user.name" :state="validateState('name')" type="text"></b-form-input>-->
                  </b-col>
                </b-row>

                <b-col cols="12" class="px-0 mt-2">
                  <b-form-group id="surname-input-group" label="Surname" label-for="surname">
                    <b-form-input
                        id="surname"
                        name="surname"
                        v-model="$v.user.surname.$model"
                        :state="validateState('surname')"
                        aria-describedby="surname-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                        id="surname-live-feedback"
                    >{{ this.invalidMesg('surname') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!--                <label>Surname</label>-->
                  <!--                <b-form-input v-model="user.surname" :state="validateState('surname')" aria-describedby="input-1-live-feedback" type="text"></b-form-input>-->
                </b-col>

                <b-col cols="12" class="px-0 mt-2">
                  <b-form-group id="email-input-group" label="Email Address" label-for="email">
                    <b-form-input
                        id="email"
                        name="email"
                        v-model="$v.user.email.$model"
                        :state="validateState('email')"
                        aria-describedby="email-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                        id="email-live-feedback"
                    >{{ this.invalidMesg('email') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!--                <label>Email Address</label>-->
                  <!--                <b-form-input v-model="user.email" type="email"></b-form-input>-->
                </b-col>

                <b-col cols="12" class="px-0 mt-2">
                  <b-form-group id="contactNumber-input-group" label="Cell Number" label-for="contactNumber">
                    <b-form-input
                        id="contactNumber"
                        name="contactNumber"
                        v-model="$v.user.contactNumber.$model"
                        :state="validateState('contactNumber')"
                        aria-describedby="contactNumber-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                        id="contactNumber-live-feedback"
                    >{{ this.invalidMesg('contactNumber') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!--                <label>Cell Number</label>-->
                  <!--                <b-form-input v-model="user.contactNumber" type="text"></b-form-input>-->
                </b-col>

                <b-col cols="12" class="px-0 mt-2">
                  <b-form-group id="landLine-input-group" label="LandLine" label-for="landLine">
                    <b-form-input
                        id="landLine"
                        name="landLine"
                        v-model="$v.user.landLine.$model"
                        :state="validateState('landLine')"
                        aria-describedby="landLine-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                        id="landLine-live-feedback"
                    >{{ this.invalidMesg('landLine') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!--                <label>LandLine</label>-->
                  <!--                <b-form-input v-model="user.landLine" type="text"></b-form-input>-->
                </b-col>

                <b-col cols="12" class="px-0 mt-2">
                  <b-form-group id="company-input-group" label="Company" label-for="company">
                    <b-form-input
                        id="company"
                        name="company"
                        v-model="$v.user.company.$model"
                        :state="validateState('company')"
                        aria-describedby="company-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                        id="company-live-feedback"
                    >{{ this.invalidMesg('company') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!--                <label>Company</label>-->
                  <!--                <b-form-input v-model="user.company" type="text"></b-form-input>-->
                </b-col>

                <b-col cols="12" class="px-0 mt-2">
                  <b-form-group id="company-input-group" label="Registration Type" label-for="registrationType">
                    <b-form-select id="registrationType"
                                   name="registrationType"
                                   v-model="$v.user.registrationType.$model"
                                   :state="validateState('registrationType')"
                                   aria-describedby="registrationType-live-feedback">
                      <!--                      <b-form-select-option value="LCS User">LCS User</b-form-select-option>-->
                      <!--                      <b-form-select-option value="LCS Partner">LCS Partner</b-form-select-option>-->
                      <!--                      <b-form-select-option value="GDP User">GDP User</b-form-select-option>-->
                      <!--                      <b-form-select-option value="TSP User">TSP User</b-form-select-option>-->


                      <!--                      Dynamic adding of Registration Type-->
                      <b-form-select-option v-for="(item, index) in registrationTypes" :key="index" :value="item.id">
                        {{ item.name }}
                      </b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback
                        id="registrationType-live-feedback"
                    >{{ this.invalidMesg('registrationType') }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>


                <b-col cols="12" class="px-0 mt-2">
                  <b-form-group id="company-input-group" label="Password" label-for="password">
                    <b-form-input
                        id="password"
                        name="password"
                        v-model="$v.user.password.$model"
                        :state="validateState('password')"
                        aria-describedby="password-live-feedback"
                        type="password"
                    ></b-form-input>

                    <b-form-invalid-feedback
                        id="password-live-feedback"
                    >{{ this.invalidMesg('password') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!--                <label>Password</label>-->
                  <!--                <b-form-input v-model="user.password" type="password"></b-form-input>-->
                </b-col>
                <b-col cols="12" class="px-0 mt-2">
                  <b-form-group id="passwordConfirm-input-group" label="Password Confirm" label-for="passwordConfirm">
                    <b-form-input
                        id="passwordConfirm"
                        name="passwordConfirm"
                        v-model="$v.user.passwordConfirm.$model"
                        :state="validateState('passwordConfirm')"
                        aria-describedby="passwordConfirm-live-feedback"
                        type="password"
                    ></b-form-input>

                    <b-form-invalid-feedback
                        id="passwordConfirm-live-feedback"
                    >{{ this.invalidMesg('passwordConfirm') }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                </b-col>


                <b-row align-h="center" class="mx-0 mb-0">
                  <div v-if="errors.length>0">
                    <b-alert variant="warning" show>Errors Found</b-alert>
                  </div>
                  <b-button class="mt-3 primary" type="submit" size="sm" variant="outline-primary" block squared>
                    <b-spinner small v-if="isLoading"></b-spinner>
                    Register
                  </b-button>
                </b-row>
              </b-form>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

  </div>

</template>

<script>
import notificationService from '@/store/notificationService'
import {mapActions, mapState} from 'vuex'
import api from "../api";
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data: () => ({
    state: 'login',
    showErrors: false,
    errors: [],
    showMsg: false,
    user: {
      name: "",
      surname: "",
      email: "",
      contactNumber: "",
      landLine: "",
      company: "",
      accountType: "",
      password: "",
      passwordConfirm: "",
      registrationType: "",
    },
    registrationTypes: [{id: "LCS User", name: "LCS User"}, {id: "LCS Partner", name: "LCS Partner"}, {
      id: "GDP User",
      name: "GDP User"
    }, {id: "TSP User", name: "TSP User"}],
    isLoading: false
  }),
  validations: {
    user: {
      name: {
        required,
        minLength: minLength(3)
      },
      surname: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      contactNumber: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)

          const containsSpecial = /[#?!@$%^&*-+]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          return !(containsUppercase || containsLowercase || containsSpecial) && containsNumber
        },
        minLength: minLength(10),
        maxLength: maxLength(10)
      },

      landLine: {
        required,
        containsNumber: function (value) {
          return /[0-9]/.test(value)
        },
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      company: {
        required,
        minLength: minLength(3)
      },

      password: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        },
        minLength: minLength(9),
        maxLength: maxLength(19)
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs("password")
      },
      registrationType: {
        required,
      },
    }
  },
  beforeCreate() {
    if (!this.$store.hasModule('notificationService')) {
      this.$store.registerModule('notificationService', notificationService);
    }
  },
  methods: {
    ...mapActions(['resetPassword']),
    ...mapActions('notificationService', ['addErrorMessage', 'addSuccessMessage']),
    redirect() {
      // window.location = "/";
      this.$router.push({path: "/"})
    },
    getCompanies() {
      api.GetVendors(s => {
        this.companies = s
        console.log(s)
      }, console.error)
    },
    validateState(name) {
      // console.log(name)
      // console.log(this.$v.user[name])
      const {$dirty, $error} = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    invalidMesg(name) {
      //Returns appropriate error message for end user.
      switch (name) {
        case "name": {
          return "This is a required field and must be at least 3 characters."
        }
        case "surname": {
          return "This is a required field and must be at least 3 characters."
        }
        case "email": {
          return "This is a required field and must be a valid Email address."
        }
        case "contactNumber": {
          return "This is a required field and must be 10 digits long.."
        }
        case "landLine": {
          return "This is a required field and must be 10 digits long."
        }
        case "company": {
          return "This is a required field and must be at least 3 characters."
        }
        case "registrationType": {
          return "This is a required field."
        }
        case "password": {
          return "This is a required field and must be at least 9 characters, must contain an Upper case and lower case letter and at lease one special character."
        }
        case "passwordConfirm": {
          return "Passwords no not Match."
        }

      }
    },
    onSubmit() {

      this.$v.$touch();
      if (this.$v.$invalid) {
        return false; // stop here if form is invalid
      } else {
        this.isLoading = true;
        console.log(this.user)
        const s = (data) => {
          console.log(data);
          console.log(data.errors);

          // ------Unhandled errors in data.errors --------------------
          if (data.errors.length > 0) {

            for (const err of data.errors) {
              alert(err)
            }
            this.showMsg = false;
          } else {
            this.showMsg = true;
          }
          // -------------------------------------------------------------
          // console.log(this.user)
          this.isLoading = false;

        }

        const e = (error) => {
          console.error(error);
          alert(error)
        }

        api.Register(this.user, s, e)

        this.isLoading = false;
      }

    }
  },
  computed: {
    ...mapState('notificationService', ['messages'])
  }
}
</script>