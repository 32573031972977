const axios = require('axios');

let authGetInstance = axios.create({
    method: "get",
    headers:
        {
            Authorization: `Bearer ${localStorage.getItem("api_Key")}`
        }
});

let authPostInstance = axios.create({
    method: "post",
    headers:
        {
            Authorization: `Bearer ${localStorage.getItem("api_Key")}`,
            'Content-Type': 'application/json'
        }
});

let nonAuthGetInstance = axios.create({
    method: "get"
});

export default {
    // Url: "https://lcswarehouse.cware.co.za/api",
    Url: "https://localhost:44383",
    authPost:authPostInstance,
    getDocumentTypes() {
        return new Promise((resolve, reject) => {
            nonAuthGetInstance({
                url: this.Url + "/DocumentWarehouse/GetDocumentTypes"
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to load Document Types");
            });
        });
    },
    getAllDocuments() {
        return new Promise((resolve, reject) => {
            nonAuthGetInstance({
                url: this.Url + "/DocumentWarehouse/GetAllDocuments"
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to load Documents");
            });
        });
    },
    searchDocuments(data) {
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/DocumentWarehouse/SearchDocuments",
                data: data
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to load documents");
            });
        });
    },
    getDocument(id) {
        return new Promise((resolve, reject) => {
            nonAuthGetInstance({
                url: this.Url + "/DocumentWarehouse/GetDocumentById/"+id
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to load Document");
            });
        });
    },
    getDocumentWithContent(id) {
        return new Promise((resolve, reject) => {
            nonAuthGetInstance({
                url: this.Url + "/DocumentWarehouse/GetDocumentWithContent/"+id
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to load Document");
            });
        });
    },
    deleteDocument(id) {
        return new Promise((resolve, reject) => {
            nonAuthGetInstance({
                url: this.Url + "/DocumentWarehouse/DeleteDocument/"+id
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to load Document");
            });
        });
    },
    createDocument(data) {
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/DocumentWarehouse/CreateDocument",
                data: data
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to create document");
            });
        });
    },
    UpdateDocument(data) {
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/DocumentWarehouse/UpdateDocument",
                data: data
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to create document");
            });
        });
    },
    getUserFolderStructure() {
        return new Promise((resolve, reject) => {
            authGetInstance({
                url: this.Url + "/DocumentWarehouse/GetUserFolder"
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to load Document");
            });
        });
    },
    createFolderStructure(data) {
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/DocumentWarehouse/CreateFolderStructure",
                data: data
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to create document");
            });
        });
    },
    updateFolderStructure(data) {
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/DocumentWarehouse/UpdateFolderStructure",
                data: data
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to create document");
            });
        });
    }
}

