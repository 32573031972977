import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Register from "../views/Register";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/forgot',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/reset',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/OuterView.vue'),
        children: [
            {
                path: '/home', name: 'home', component: () => import('../views/Home.vue'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/demo', name: 'Demo', component: () => import('../views/Demo'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/invoiceTracker', name: 'InvoiceTracker', component: () => import('../views/InvoiceTracker'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/invoiceTracker/view/', name: 'OrderViewer', component: () => import('../views/OrderViewer'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/IframeViewer', name: 'IframeViewer', component: () => import('../views/IframeViewer'),
                meta: {
                    requiresAuth: true
                }
            },


            //Admin Manage Users

            {
                path: '/admin/ManageUser/SearchUser',
                name: 'SearchUser',
                component: () => import('../views/admin/ManageUser/SearchUser'),
                meta: {
                    requiresAuth: true
                }
            },


            {
                path: '/admin/ManageUser/ViewUser',
                name: 'ViewUser',
                component: () => import('../views/admin/ManageUser/ViewUser'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageUser/CreateUser',
                name: 'CreateUser',
                component: () => import('../views/admin/ManageUser/CreateUser'),
                meta: {
                    requiresAuth: true
                }
            },
            //Admin Manage Tags

            {
                path: '/admin/ManageTags/SearchTag',
                name: 'SearchTag',
                component: () => import('../views/admin/ManageTags/SearchTag'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageTags/ViewTag',
                name: 'ViewTag',
                component: () => import('../views/admin/ManageTags/ViewTag'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageTags/CreateTag',
                name: 'CreateTag',
                component: () => import('../views/admin/ManageTags/CreateTag'),
                meta: {
                    requiresAuth: true
                }
            },
            //Admin Manage Roles

            {
                path: '/admin/ManageRoles/SearchRole',
                name: 'SearchRole',
                component: () => import('../views/admin/ManageRoles/SearchRole'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageRoles/RoleView',
                name: 'RoleView',
                component: () => import('../views/admin/ManageRoles/RoleView'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageRoles/CreateRole',
                name: 'CreateRole',
                component: () => import('../views/admin/ManageRoles/CreateRole'),
                meta: {
                    requiresAuth: true
                }
            },
            //Admin Manage  Organisations

            {
                path: '/admin/ManageOrganisations/SearchOrganisation',
                name: 'SearchOrganisation',
                component: () => import('../views/admin/ManageOrganisations/SearchOrganisation'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageOrganisations/OrganisationView',
                name: 'OrganisationView',
                component: () => import('../views/admin/ManageOrganisations/OrganisationView'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageOrganisations/CreateOrganisation',
                name: 'CreateOrganisation',
                component: () => import('../views/admin/ManageOrganisations/CreateOrganisation'),
                meta: {
                    requiresAuth: true
                }
            },
            //Admin Manage Apps And Services

            {
                path: '/admin/ManageLinks/SearchLink',
                name: 'SearchLink',
                component: () => import('../views/admin/ManageLinks/SearchLink'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageLinks/LinkView',
                name: 'LinkView',
                component: () => import('../views/admin/ManageLinks/LinkView'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageLinks/CreateLink',
                name: 'CreateLink',
                component: () => import('../views/admin/ManageLinks/CreateLink'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageGroupings/GroupingView/:id',
                name: 'GroupingView',
                component: () => import('../views/admin/ManageGroupings/GroupingView'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageGroupings/GroupingAdd',
                name: 'GroupingView',
                component: () => import('../views/admin/ManageGroupings/GroupingView'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageGroupings/GroupingSearch',
                name: 'GroupingSearch',
                component: () => import('../views/admin/ManageGroupings/GroupingSearch'),
                meta: {
                    requiresAuth: true
                }
            },

            // Admin Manage Documents
            {
                path: '/admin/ManageDocuments/DocumentTypes',
                name: 'ManageDocumentTypes',
                component: () => import('../views/admin/ManageDocuments/ManageDocumentTypes'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageDocuments/DocumentAccess',
                name: 'ManageDocumentAccess',
                component: () => import('../views/admin/ManageDocuments/ManageDocumentAccess'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageDocuments/DocumentAccess/GrantAccess',
                name: 'GrantAccess',
                component: () => import('../views/admin/ManageDocuments/GrantAccess'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/admin/ManageDocuments/DocumentAccess/ViewAccess/:userId',
                name: 'ViewAccess',
                component: () => import('../views/admin/ManageDocuments/ViewUserAccess'),
                meta: {
                    requiresAuth: true
                }
            },
            // Reports
            {
                path: '/Repots/ViewAllReports',
                name: 'ViewAllReports',
                component: () => import('../views/Reports/ViewAllReports'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Repots/IncomeStatement',
                name: 'IncomeStatement',
                component: () => import('../views/Reports/IncomeStatement'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Repots/Load', name: 'Load', component: () => import('../views/Reports/Load'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Repots/Position', name: 'Position', component: () => import('../views/Reports/Position'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Repots/Performance',
                name: 'Performance',
                component: () => import('../views/Reports/Performance'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Repots/FuelConsumption',
                name: 'FuelConsumption',
                component: () => import('../views/Reports/FuelConsumption'),
                meta: {
                    requiresAuth: true
                }
            },
            // Apps
            {
                path: '/Apps/ViewAllApplication',
                name: 'ViewAllApplication',
                component: () => import('../views/Apps/ViewAllApplication'),
                meta: {
                    requiresAuth: true
                }
            },

            // Services
            {
                path: '/Services/ViewAllServices',
                name: 'ViewAllServices',
                component: () => import('../views/Services/ViewAllServices'),
                meta: {
                    requiresAuth: true
                }
            },
            // Power Bi POC
            {
                path: '/PowerBiViewer', name: 'powerBiViewer', component: () => import('../views/PowerBiViewer'),
                meta: {
                    requiresAuth: true
                }
            },
            // Document warehouse
            {
                path: '/DocumentWarehouse/Documents', name: 'DocumentList', component: () => import('../views/DocumentList'),
                meta: {
                    requiresAuth: false
                }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    var token = JSON.parse(localStorage.getItem('user_data'))
    var expired = (token === null)

    //IF token is found in storage
    if (token !== null) {
        //Calc Exp date
        var exp = new Date(token.exp * 1000);

        expired = exp < Date.now()
        if (expired) {
            token = null
            localStorage.clear()
        }

    }

    // If logged in, or going to the Login page.
    //exclusive or - If token exists exclusively continue to next page - If Token doesnt exist and going to the following pages continue
    var bContinue = ((to.name === 'Register' || to.name === 'ResetPassword' || to.name === 'ForgotPassword' || to.name === 'Login') && expired) || (!(to.name === 'Register' || to.name === 'ResetPassword' || to.name === 'ForgotPassword' || to.name === 'Login') && !expired)
    var bHome = !expired && (to.name === 'Register' || to.name === 'ResetPassword' || to.name === 'ForgotPassword' || to.name === 'Login')
    var bLogin = !(!expired && to.name === 'Register' && to.name === 'ResetPassword' && to.name === 'ForgotPassword') || (expired && to.name === 'Login')
    
    // bContinue = true;
    
    if (bContinue) {
        next()

    } else if (bHome) {
        // Continue to page.
        next({name: 'home'})

    } else if (bLogin) {
        // Not logged in, redirect to login.
        next({name: 'Login'})
    }

    // Test if user can access Admin Page
    if (to.path.startsWith("/admin/") && token.role.Name === "Admin") {
        next()
    } else if (to.path.startsWith("/admin/") && token.role.Name !== "Admin") {
        next({name: 'Home'})
    }

});

export default router
