const axios = require('axios');

let authGetInstance = axios.create({
    method: "get",
    headers:
        {
            Authorization: `Bearer ${localStorage.getItem("api_Key")}`
        }
});

let authPostInstance = axios.create({
    method: "post",
    headers:
        {
            Authorization: `Bearer ${localStorage.getItem("api_Key")}`,
            'Content-Type': 'application/json'
        }
});

let nonAuthGetInstance = axios.create({
    method: "get"
});

let nonAuthPostInstance = axios.create({
    method: "post",
    headers:
        {
            'Content-Type': 'application/json'
        }
});

export default {
    Url: "https://localhost:44383",
    //Associated Entity
    authPost:authPostInstance,

    getGroupings(s, e) {
        authGetInstance({

            url: this.Url + "/Grouping/GetAll"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load associated grouping");
            });
    },
    getGrouping(id, s, e) {
        authGetInstance({

            url: this.Url + "/Grouping/Get/"+id
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load associated grouping");
            });
    },
    updateGrouping(data, s, e) {
        authPostInstance({
            url: this.Url + "/Grouping/Update",
            data: data
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    addGrouping(data, s, e) {
        authPostInstance({
            url: this.Url + "/Grouping",
            data: data
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },
    deleteGrouping(id, s, e) {
        authGetInstance({
            url: this.Url + "/Grouping/Delete/"+id
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete Grouping.");
            });
    },
    // Users 
    getAllUsers(s, e) {
        authGetInstance({
            url: this.Url + "/User/GetAll"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load users");
            });
    },
    getUserID(userEmail, s, e) {
        authGetInstance({
            url: this.Url + "/User/FindByEmail/" + userEmail
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to users by email");
            });
    },
    getUserById(userId, s, e) {
        authGetInstance({
            url: this.Url + "/User/Get/" + userId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to users by ID");
            });
    },

    CreateUser(user, s, e) {
        authPostInstance({
            url: this.Url + "/User/Create",
            data: user
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.message.detail);
            });
    },

    UpdateUser(user, s, e) {
        authPostInstance({
            url: this.Url + "/User/update",
            data: user
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e(error.response.data);
            });
    },

    DeleteUser(user, s, e) {
        authPostInstance({
            url: this.Url + "/User/delete",
            data: user
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load users");
            });
    },
    GetVendors(s, e){
        authGetInstance({
            url: this.Url + "/Vendor",


        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Vendor ID");
            });
    },
    GetCustomers(s, e){
        authGetInstance({
            url: this.Url + "/Customer",

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Customer ID");
            });
    },
    GetVendorIdFromCompanyName(companyName, s, e){
        authGetInstance({
            url: this.Url + "/Vendor/FindbyName?name="+companyName,
            method: "get"

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Vendor ID");
            });
    },
    GetVendorFromId(Id, s, e){
        authGetInstance({
            url: this.Url + "/Vendor/FindbyID?id="+Id,
            method: "get"

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get company ID");
            });
    },
    //Tags
    getAllTags(s, e) {
        authGetInstance({
            url: this.Url + "/Tags/GetAll"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Tags");
            });
    },
    getTagID(tagId, s, e) {
        authGetInstance({
            url: this.Url + "/Tags/get/" + tagId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed To load Tag");
            });
    },
    DeleteTag(tag, s, e) {
        authPostInstance({
            url: this.Url + "/Tags/delete",
            data: tag
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete tags");
            });
    },
    UpdateTag(tag, s, e) {
        authPostInstance({
            url: this.Url + "/Tags/update",
            data: tag
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update tags");
            });
    },
    CreateTag(tag, s, e) {
        authPostInstance({
            url: this.Url + "/Tags/create",
            data: tag
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to create tag");
            });
    },

    //Roles

    getRoles(s, e) {
        authGetInstance({
            url: this.Url + "/Role/GetAll"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load roles");
            });
    },
    getRoleID(roleId, s, e) {
        authGetInstance({
            url: this.Url + "/Role/get/" + roleId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get roles by id");
            });
    },

    CreateRole(role, s, e) {
        authPostInstance({
            url: this.Url + "/Role/create",
            data: role
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete role");
            });
    },

    UpdateRole(role, s, e) {
        authPostInstance({
            url: this.Url + "/Role/update",
            data: role
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update role");
            });
    },

    DeleteRole(role, s, e) {
        authPostInstance({
            url: this.Url + "/Role/delete",
            data: role
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete role");
            });
    },

    // Organisation 

    getAllOrganisation(s, e) {
        authGetInstance({
            url: this.Url + "/Organisation/GetAll"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Organisations");
            });
    },
    getOrganisationID(organisationId, s, e) {
        authGetInstance({
            url: this.Url + "/Organisation/get/" + organisationId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Organisation by id");
            });
    },

    CreateOrganisation(organisation, s, e) {
        authPostInstance({
            url: this.Url + "/Organisation/Create",
            data: organisation
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete Organisation");
            });
    },

    UpdateOrganisation(organisation, s, e) {
        authPostInstance({
            url: this.Url + "/Organisation/update",
            data: organisation
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update Organisation");
            });
    },

    DeleteOrganisation(organisation, s, e) {
        authPostInstance({
            url: this.Url + "/Organisation/delete",
            data: organisation
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete Organisation");
            });
    },

    // Applications and services 
    getAllLinks(s, e) {
        authGetInstance({
            url: this.Url + "/Links/GetAll"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load application and services");
            });
    },
    getLinksID(LinkId, s, e) {
        authGetInstance({
            url: this.Url + "/Links/get/" + LinkId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Application And Service by id");
            });
    },

    CreateLinks(Link, s, e) {
        authPostInstance({
            url: this.Url + "/Links/Create",
            data: Link
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to create link");
            });
    },

    UpdateLinks(Link, s, e) {
        authPostInstance({
            url: this.Url + "/Links/update",
            data: Link
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to update Application And Service");
            });
    },
    DeleteLinks(Link, s, e) {
        authPostInstance({
            url: this.Url + "/Links/delete",
            data: Link
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete Application And Service");
            });
    },

    // Auth Login
    getAuthLogin(email, password, s,t, e) {
        nonAuthPostInstance({
            url: this.Url + "/Auth/Login",
            data: {
                "email": email,
                "password": password,
            }
        })
            .then(function (resp) {
                s(resp.data);
            })
            .then(function (){
                t()
            })
            .catch(function (error) {
                console.error(error);
                e(error.response.data);
            });
    },

    // TODO: Please sort this out in the back end - another get call registered as a post call.
    getAuthRegister(s, e) {
        nonAuthPostInstance({
            url: this.Url + "/Auth/Register"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to Register Users");
            });
    },

    resetPassword(email, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/Auth/ResetPassword?email=" + email
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to Reset Password");
            });
    },

    FinishResetPassword(resetModel, s, e) {
        nonAuthPostInstance({
            url: this.Url + "/Auth/FinishResetPassword",
            data:resetModel
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to Reset Password");
            });
    },

    Register(userDto, s, e) {
        nonAuthGetInstance({
            url: this.Url + "/User/PublicRegister",
            data: userDto,
            method: "post",
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to Register");
            });
    },
    GetRedirectLink(email, s, e) {
        authGetInstance({
            url: this.Url + "/Links/GetRedirectLink?email=" + email,
            method: "post"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to Register");
            });
    },GetInvoiceData(date, s, e) {
        authGetInstance({
            url: this.Url + "/Invoice/GetInvoiceData?date=" + date,
            method: "get"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Invoice Data");
            });
    },

    GetInvoiceDataAll(filterDTO, s, e) {
        authPostInstance({
            url: this.Url + "/Invoice/GetInvoiceData/All",
            data: filterDTO,
            method: "post",
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Invoice Data");
            });
    },
    GetInvoiceChartData(currentYear, filterDTO, s, e) {
        
        authPostInstance({
            url: this.Url + "/Invoice/GetChartData/"+currentYear,
            data: filterDTO,
            method: "post",
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Invoice Chart Data");
            });
    },
    // GetInvoiceDataAll(s, e) {
    //     authGetInstance({
    //         url: this.Url + "/Invoice/GetInvoiceData/All",
    //         method: "get"
    //     })
    //         .then(function (resp) {
    //             s(resp.data);
    //         })
    //         .catch(function (error) {
    //             console.error(error);
    //             e("Failed to load Invoice Data");
    //         });
    // },
    GetInvoiceDatabyVendorId(date, vendorId, s, e) {
        authGetInstance({
            url: this.Url + "/Invoice/GetInvoiceData?date=" + date+"&vendorId="+vendorId,
            method: "get"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Invoice Data");
            });
    },
    GetInvoiceDatebyVendorId(vendorId, s, e){
        authGetInstance({
            url: this.Url + "/Invoice/GetInvoiceDates?vendorId="+vendorId,
            method: "get"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Invoice Dates");
            });
    },
    GetInvoiceStatusGroupedData(year, s, e) {
        authGetInstance({
            url: this.Url + "/Invoice/GetInvoiceStatusGroupedData?year=" + year,
            method: "get"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Grouped Invoice Data");
            });
    },
    GetOrderbyID(orderid, s, e) {
        authGetInstance({
            url: this.Url + "/Invoice/GetOrderDetails?orderid=" + orderid,
            method: "get"
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Invoice Data");
            });
    },
    // GetOrderbyID(orderid) {
    //     return new Promise((resolve, reject) => {
    //         authGetInstance({
    //             url: this.Url + "/Invoice/GetOrderStatus?orderid=" + orderid,
    //             method: "get"
    //         })
    //         .then(resp => {
    //             resolve(resp.data);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //             reject("Failed to load Invoice Data");
    //         });
    //     });
    // },
    GetOrderDetailsByIds(orderIds) {
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + `/Invoice/GetOrderDetailsByIds`,
                data: orderIds
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to load invoice line details");
            });
        });
    },
    IsOrderInQueue(reference) {
        return new Promise((resolve, reject) => {
            authGetInstance({
                url: this.Url + `/Pastel/IsInQueue?reference=${reference}`,
                method: "get"
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to load exported invoice");
            });
        });
    },
    GetLoadDateRate(serviceItemId, vendorId, date) {
        const queryString = `serviceItemId=${serviceItemId}&vendorId=${vendorId}&date=${date}`;
        return new Promise((resolve, reject) => {
            authGetInstance({
                url: this.Url + `/Invoice/GetZulaRate?${queryString}`,
                method: "get"
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to fetch Zula Rate");
            });
        });
    },
    GetExportPastelInvoices(BulkOrderNo, OrderType) {
        return new Promise((resolve, reject) => {
            authGetInstance({
                url: this.Url + "/Pastel/GetExportedInvoices?bulkOrderNo=" + BulkOrderNo + "&orderType=" + OrderType,
                method: "get"
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to load exported Pastel invoices.");
            });
        });
    },
    GetExportedInvoiceByReference(reference) {
        return new Promise((resolve, reject) => {
            authGetInstance({
                url: this.Url + "/Pastel/GetExportedInvoiceByReference?reference=" + reference,
                method: "get"
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to load exported Pastel invoices.");
            });
        });
    },
    SalesOrderPlaceOrder(saleOrder, isMultiLine) {
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/Pastel/SalesOrderPlaceOrder?isMultiline="+isMultiLine,
                data: saleOrder
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to export Invoice/s to Pastel");
            });
        });
    },
    SalesOrderPlaceOrderExisting(saleOrder, bulkOrderNo) {
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/Pastel/SalesOrderPlaceOrderExisting?bulkOrderNo=" + bulkOrderNo ,
                data: saleOrder
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to export Invoice/s to Pastel");
            });
        });
    },
    PurchaseOrderExisting(saleOrder, bulkOrderNo) {
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/Pastel/PurchaseOrderExisting?bulkOrderNo=" + bulkOrderNo ,
                data: saleOrder
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to export Invoice/s to Pastel");
            });
        });
    },
    PurchaseOrderPlaceOrder(saleOrder, isMultiLine) {
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/Pastel/PurchaseOrderPlaceOrder?isMultiline="+isMultiLine,
                data: saleOrder
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to export Invoice/s to Pastel");
            });
        });
    },
    UpdateInvoiceStatus(orderId, entryId) {
        return new Promise((resolve, reject) => {
            authGetInstance({
                url: this.Url + "/Invoice/SetEntryId/" + orderId+"/"+entryId,
                method: "get"
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to update Invoice Data");
            });
        });
    },
    UploadSupportingDocs(files, s, e) {
        authPostInstance({
            url: this.Url + "/Invoice/UploadSupportDoc",
            data: files
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to upload document");
            });
    },
    LinkSupportingDocs(fileId,s,e){
        authPostInstance({
            url: this.Url + "/Invoice/LinkFile",
            data: fileId
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to upload document");
            });
    },
    GetLinkedSupportingDocs(orderId,s,e){
        authGetInstance({
            url: this.Url + "/Invoice/GetLinkedFiles?orderId="+orderId,
            method: "get"

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get linked supporting document");
            });
    },
    DeleteLinkedSupportingDocs(fileId,s,e){
        authPostInstance({
            url: this.Url + "/Invoice/DeleteLinkedFiles?fileId="+fileId,


        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to delete supporting document");
            });
    },

    GetReport(guid, s, e) {
        authGetInstance({
            url: this.Url + "/PowerBi/GetReport/" + guid
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Error loading PowerBi Report");
            });
    },  

    GetUserFavourites(s, e) {
        // console.log("GetUserFavourites")
        let user = JSON.parse(localStorage.getItem("user_data"))
        // console.log(user)
        let useremail = user.email
        authGetInstance({
            url: this.Url + "/Fav/List?useremail=" + useremail
        })
        .then(function (resp) {
            s(resp.data);
        })
        .catch(function (error) {
            console.error(error);
            e("Failed to get User Favourites.");
        });

    },
    

    AddToUserFavourites(request, s, e) {
        console.log("AddUserFavourites", request)
        authPostInstance({
            url: this.Url + "/Fav/Add",
            data: request
        })
        .then(function (resp) {
            s(resp.data);
        })
        .catch(function (error) {
            console.error(error);
            e("Failed to add item to user Favourites");
        });

    },

    RemoveFromUserFavourites(request, s, e) {
        console.log("RemoveserFavourites")
        authPostInstance({
            url: this.Url + "/Fav/Remove",
            data: request
        })
        .then(function (resp) {
            s(resp.data);
        })
        .catch(function (error) {
            console.error(error);
            e("Failed to remove from user Favourites");
        });

    },
    SetOrderStatus(request){
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/Invoice/SetOrderStatus",
                data: request
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to set Order Status");
            });
        });
    },
    ExportToExcel(request) {
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/Invoice/ExportToExcel",
                data: request,
                responseType: 'blob'
            })
            .then(resp => {
                resolve(resp); // Resolve with the full response to include headers
            })
            .catch(error => {
                console.error(error);
                reject("Failed to export invoices to excel.");
            });
        });
    },    
    SetOrderStatuses(request){
        return new Promise((resolve, reject) => {
            authPostInstance({
                url: this.Url + "/Invoice/SetOrderStatuses",
                data: request
            })
            .then(resp => {
                resolve(resp.data);
            })
            .catch(error => {
                console.error(error);
                reject("Failed to set Order Statuses");
            });
        });
    },
    GetOrderStatus(orderid,s,e){
        authGetInstance({
            url: this.Url + "/Invoice/GetOrderStatus?orderid="+orderid,

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Order Status");
            });
    },
    // ===============Document Generator System=======================
    DocumentGetAccessList(s,e){
        authGetInstance({
            url: this.Url + "/Document/GetAllUserAccess",

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Access List");
            });
    },
    DocumentGetUserAccess(userId,s,e){
        var url = this.Url + "/Document/GetDocumentAccess"
        if (userId!=null){
            url+="?userId="+userId
        }
        authGetInstance({
            url: url,

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get User Access List");
            });
    },
    DocumentGetUsers(s,e){
        authGetInstance({
            url: this.Url + "/Document/GetAllUsers",

        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to get Users");
            });
    },
    DocumentApplyAccess(userAccess,s,e){
        authPostInstance({
            url: this.Url + "/Document/ApplyAccess",
            data:userAccess
        })
            .then(function (resp) {
                s(resp.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to apply user Access");
            });
    }
}

